import InfiniteScroll from 'react-infinite-scroller';
import styles from '../../Templatel.Select.module.scss';
import NewsletterTemplateCard from '../../../NewsletterTemplateCard';
import useTemplatesList from './useTemplatesList';
import EmptyTable from '../../../../../settings/subscriber-fields/components/fields-table/EmptyTable';

const TemplatesList = ({title, ...rest}) => {
  const {templates, page, hasMore, loading, handleFetchMore, handleClick} = useTemplatesList(rest);

  return (
    <InfiniteScroll
      pageStart={page}
      hasMore={hasMore}
      loadMore={handleFetchMore}
      initialLoad={false}
      useWindow={false}
      getScrollParent={() => document.querySelector('#root')}
    >
      <h6 className={styles.label}>{title}</h6>
      <div className={styles.block}>
        {templates.map((template, index) => (
          <NewsletterTemplateCard
            className={styles.card}
            key={`${template.id}_idx_${index}`}
            template={template}
            onClick={handleClick(template)}
          />
        ))}
      </div>
      {!loading && !templates?.length && <EmptyTable>No templates were found.</EmptyTable>}
    </InfiniteScroll>
  );
};

export default TemplatesList;
