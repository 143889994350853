import {useCallback, useState} from 'react';
import {useLocation} from 'react-router';
import BackButton from '../../../components/BackButton';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import CardHeader from '../../../components/Card/CardHeader';
import useRouter from '../../../hooks/useRouter';
import ROUTES from '../../../Router/routes';
import Toolbar from '../newsletter/Toolbar/Toolbar';
import StripoEditor from '../../../components/StripoEditor';
import emptyEmailTemplate from '../../../components/StripoEditor/templates/empty';
import {TEMPLATE_CATEGORY} from './constants';
import {generateEmailIdLocally} from '../../../utils/emailId';
import SaveAsTemplateModal from '../../../components/SaveAsTemplateModal';
import {getTemplateAsync} from '../../../components/StripoEditor/utils/v1/asyncWrappers';

const TemplateEditor = () => {
  const {history} = useRouter();
  const location = useLocation();

  const [editorIsInitialized, setEditorIsInitialized] = useState(false);
  const [isOpenTemplateModal, setOpenTemplateModal] = useState(null);

  const [templateName, setTemplateName] = useState(location?.state?.template?.name);
  const isTemplateEditMode = location?.state?.isEditTemplateMode;

  const stripoTemplate = location?.state?.template
    ? {html: location?.state?.template?.htmlContent, css: location?.state?.template?.cssContent}
    : emptyEmailTemplate;

  const openSaveTemplateModal = useCallback(async () => {
    const [html, css] = await getTemplateAsync();
    setOpenTemplateModal({html, css});
  }, []);

  const handleInitializationOfEditor = useCallback(() => setEditorIsInitialized(true), []);

  return (
    <div style={{display: 'flex'}}>
      <div style={{flex: 1}}>
        <BackButton noMargin to={ROUTES.newsletters.templates}>
          All Templatets
        </BackButton>

        <Card
          header={
            <CardHeader title={templateName || 'New Template'}>
              <Button
                type="button"
                variant="outlined"
                color="green"
                disabled={!editorIsInitialized}
                onClick={openSaveTemplateModal}
              >
                {isTemplateEditMode ? 'Update' : 'Save'}
              </Button>
            </CardHeader>
          }
        >
          <StripoEditor
            template={stripoTemplate}
            emailId={generateEmailIdLocally()}
            onInit={handleInitializationOfEditor}
          />
          {Boolean(isOpenTemplateModal) && (
            <SaveAsTemplateModal
              isOpen={Boolean(isOpenTemplateModal)}
              defaultTemplateName={templateName}
              onNameUpdate={setTemplateName}
              defaultTemplateCategory={
                location?.state?.template?.category ?? TEMPLATE_CATEGORY.BULK_MAIL
              }
              onClose={() => setOpenTemplateModal(null)}
              html={isOpenTemplateModal?.html ?? ''}
              css={isOpenTemplateModal?.css ?? ''}
              templateId={isTemplateEditMode ? location?.state?.template?.id : undefined}
              onAfterRequest={() => {
                setOpenTemplateModal(null);
                history.push(ROUTES.newsletters.templates);
              }}
            />
          )}
        </Card>
      </div>
      <Toolbar />
    </div>
  );
};

export default TemplateEditor;
