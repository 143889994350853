export const actions = {
  SELECT_FORM: 'SELECT_FORM',
  SET_FORM_DATA: 'SET_FORM_DATA',
  ADD_OR_UPDATE_FORM_DATA: 'ADD_OR_UPDATE_FORM_DATA',
  SET_FORM_TYPES: 'SET_FORM_TYPES',
  SET_VALIDATION_ERRORS: 'SET_VALIDATION_ERRORS',
  SET_BUTTON_CONFIG_PARAM: 'SET_BUTTON_CONFIG_PARAM',
  TOGGLE_TOOLBAR: 'TOGGLE_TOOLBAR',
  TOGGLE_EMAIL_EDITOR: 'TOGGLE_EMAIL_EDITOR',
};

export const ENDPOINTS = {
  fetchFormTypes: '/signup-component/types',
  fetchFormContent: (type) => `/signup-component/${type}`,
  addOrUpdate: '/signup-component',
  deleteFormContent: (type) => `/signup-component/${type}`,
};

export const defaultSubmitConfig = {
  isButton: true,
  buttonStyle: {
    width: '60%',
    padding: 10,
    borderRadius: 5,
    color: '#FFFFFF',
    backgroundColor: '#0ED2AF',
    fontSize: 16,
    border: '1px solid',
    borderColor: '#0ED2AF',
    textAlign: 'center',
    url: '',
  },
  linkStyle: {
    color: '#109cf1',
    textAlign: 'center',
    fontSize: 16,
  },
  wrapperStyle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'CENTER',
  },
};
