import {useCallback, useState} from 'react';
import {useLocation} from 'react-router';
import styles from './Templatel.Select.module.scss';
import {TEMPLATE_TYPE, categoryOptions} from '../constants';
import useRouter from '../../../../hooks/useRouter';
import {TemplatesList} from './components';
import SearchInput from '../../../../components/forms/SearchInput';
import Button from '../../../../components/Button';

const TemplateSelect = () => {
  const location = useLocation();
  const {history} = useRouter();
  const [search, setSearch] = useState('');

  const [categories, setCategories] = useState(categoryOptions.map(({value}) => value));

  const handelClickCategory = useCallback(
    (category) => () => {
      setCategories((prev) => {
        if (prev?.length === 1 && prev.includes(category)) {
          return prev;
        }

        return prev.includes(category)
          ? prev.filter((item) => item !== category)
          : [...prev, category];
      });
    },
    []
  );

  if (!location.state?.back) {
    return history.goBack();
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5 className={styles.title}>Select a template to customize</h5>
        <p className={styles.subtitle}>
          Create your own personalized template! Choose which one to create.
        </p>
      </div>

      <div className={styles.content}>
        <div className={styles.filters}>
          <SearchInput
            onSearch={(v) => setSearch(v ?? null)}
            placeholder="Search for templates"
            name=""
          />
          <div>
            {categoryOptions.map(({label, value}) => (
              <Button
                key={label}
                variant="filled"
                onClick={handelClickCategory(value)}
                color={categories.includes(value) ? 'blue' : 'grey'}
              >
                {label}
              </Button>
            ))}
          </div>
        </div>

        <div className={styles.body}>
          <TemplatesList
            title="Your Templates"
            type={TEMPLATE_TYPE.CUSTOM}
            categories={categories}
            search={search}
          />
          <TemplatesList
            title="Standard Templates"
            type={TEMPLATE_TYPE.SYSTEM}
            categories={categories}
            search={search}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateSelect;
