import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import useStripoScript from 'hooks/useStripoScript';
import {useSubscruberFields} from 'hooks/queries/subscriber';
import Spinner from 'components/Spinner';
import Bugsnag from '@bugsnag/js';
import styles from './StripoEditor.module.scss';
import useStripoToken from './hooks/useStripoToken';
import * as cssVars from './styles/variables';
import './styles/stripo-overrides.scss';
import useAlert from '../../domain/alerts/useAlert';
import LinkInput from './components/LinkInput';
import Modal from '../Modal';

const createPersonalizationTagsFromSubscriberFields = (fields = []) => {
  return fields.map((field) => ({
    label: field.name,
    value: `{{${field.personalizationTagName}}}`,
  }));
};

//
const editorCss = `
  .esd-empty-container {
    background: ${cssVars.colors.accent100};
    color: ${cssVars.colors.accent200};
    border-color: ${cssVars.colors.accent200};
    border-radius: 5px;
  }
  .esd-hidden-footer {
    display: none;
    overflow: hidden;
    width: 0;
    max-height: 0;
    line-height: 0;
    mso-hide: all;
  }
`;

// TODO: handle errors from stripo script loader, stripo auth
function StripoEditor({emailId, template, className, onInit, height = '95vh'}) {
  const stripoScript = useStripoScript();
  const stripoTokenQuery = useStripoToken();
  const subscriberQuery = useSubscruberFields();
  const {showAlert} = useAlert();

  const [editLink, setEditLink] = useState(null);

  useEffect(() => {
    if (!stripoScript.loaded || subscriberQuery.loading) {
      if (stripoScript.error && stripoScript.errorMessage) {
        showAlert('Failed to load editor', {
          variant: 'error',
          dismiss: false,
        });
      }
      return;
    }

    window.Stripo.init({
      settingsId: 'stripoSettingsContainer',
      previewId: 'stripoPreviewContainer',
      html: template.html,
      css: template.css,
      localePatch: {
        'mergeTags.label': {
          en: 'Personalization Tags',
        },
      },
      apiRequestData: {
        emailId,
      },
      customTextLink: {
        activate(params, linkCallback) {
          setEditLink({element: params.element?.$ ?? params.element, linkCallback});
        },
        showOpenCustomLinkIcon: true,
      },
      mergeTags: [
        {
          category: '',
          entries: createPersonalizationTagsFromSubscriberFields(subscriberQuery.data),
        },
      ],
      getAuthToken(callback) {
        stripoTokenQuery
          .run()
          .then(callback)
          .catch((e) => {
            callback(null);
            showAlert('Failed to load editor', {
              variant: 'error',
              dismiss: false,
            });
            Bugsnag.notify(e);
          });
      },
      onTemplateLoaded: () => {
        onInit?.();
        window.EmpleApp.activateCustomViewStyles(true);
      },
      customViewStyles: editorCss,
    });

    return () => window.StripoApi?.stop();
  }, [
    emailId,
    template.html,
    template.css,
    stripoScript.loaded,
    subscriberQuery.loading,
    subscriberQuery.data,
  ]);

  return (
    <div className={cx(styles.stripoContainer, 'stripo-editor', className)}>
      <div
        id="stripoPreviewContainer"
        className={styles.editorView}
        style={{minHeight: height, maxHeight: height}}
      >
        <div className={styles.loader}>
          <Spinner />
        </div>
      </div>
      <div
        id="stripoSettingsContainer"
        className={styles.editorSettings}
        style={{minHeight: height, maxHeight: height}}
      />
      <Modal isOpen={Boolean(editLink)} onRequestClose={() => setEditLink(null)} title="Add link">
        <LinkInput element={editLink?.element} linkCallback={editLink?.linkCallback} showAddTag />
      </Modal>
    </div>
  );
}

StripoEditor.propTypes = {
  template: PropTypes.shape({
    css: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
  }).isRequired,
  emailId: PropTypes.string.isRequired,
  className: PropTypes.string,
  onInit: PropTypes.func,
  height: PropTypes.string,
};

export default StripoEditor;
