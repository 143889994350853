import {useForm, Controller} from 'react-hook-form';
import {fetchTagsService} from '../../../../domain/settings/subscriber-fields/service';
import useFetch from '../../../../hooks/useFetch';
import Button from '../../../Button';
import Input from '../../../forms/Input';
import Select from '../../../forms/Select';
import {REQUIRED_FIELD_MESSAGE} from '../../../forms/constants';

const LinkInput = ({element, linkCallback, onRequestClose, showAddTag}) => {
  const {data} = useFetch(fetchTagsService, {
    throwError: true,
    immediate: true,
  });

  const {errors, control, handleSubmit, register} = useForm({
    mode: 'onBlur',
    defaultValues: {
      tag: element?.getAttribute('data-id'),
      url: element?.getAttribute('href'),
      text: element?.innerText,
    },
  });

  return (
    <div>
      <Input label="URL" error={errors.url} name="url" inputRef={register} />
      <Input label="Text" error={errors.text} name="text" inputRef={register} />
      {Boolean(data && data.length && showAddTag) && (
        <Controller
          name="tag"
          error={errors.tag}
          control={control}
          rules={{required: REQUIRED_FIELD_MESSAGE}}
          as={Select}
          label="Add a tag"
          required
          options={data.map((item) => ({label: item.name, value: item.id}))}
        />
      )}
      <Button
        color="green"
        variant="filled"
        full
        onClick={handleSubmit(({url, text, tag}) => {
          linkCallback?.({url, text});
          element?.setAttribute('data-id', tag);
          onRequestClose?.();
        })}
      >
        Save
      </Button>
    </div>
  );
};

export default LinkInput;
