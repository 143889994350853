import {useLocation} from 'react-router';
import {useCallback} from 'react';
import useRouter from '../../../../../../hooks/useRouter';
import useFetch from '../../../../../../hooks/useFetch';
import {fetchTemplateService} from '../../../../service';
import useFetchTemplate from '../../../../hooks/useFetchTemplate';

const useTemplatesList = ({categories, search, type}) => {
  const {history} = useRouter();
  const location = useLocation();

  const {templates, page, hasMore, loading, handleFetchMore} = useFetchTemplate(
    type,
    categories,
    search
  );

  const {run: fetchTemplateRequest} = useFetch(fetchTemplateService, {
    throwError: true,
  });

  const handleClick = useCallback(
    (template) => async () => {
      const templateData = await fetchTemplateRequest(template?.id);
      history.push({
        pathname: location.state?.back,
        state: {template: templateData, data: location.state?.data},
      });
    },
    [fetchTemplateRequest, history, location.state?.back, location.state?.data]
  );

  return {templates, page, loading, handleFetchMore, handleClick, hasMore};
};

export default useTemplatesList;
